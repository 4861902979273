import {Controller} from "stimulus";

// function getVoices() {
//     return new Promise(
//       function (resolve, reject) {
//           let synth = window.speechSynthesis;
//           let id;
//
//           id = setInterval(() => {
//               if (synth.getVoices().length !== 0) {
//                   resolve(synth.getVoices());
//                   clearInterval(id);
//               }
//           }, 10);
//       }
//     )
// }
export default class extends Controller {
    connect() {
        this.element.querySelectorAll('p').forEach(p => {
            p.addEventListener('click', () => this.read(p))
        })
        // getVoices().then((voices) => {
        //     this.voice = voices[2]
        // })
    }

    disconnect() {
        this.stop();
    }

    reading;

    read(paragraph) {
        if (this.reading && window.speechSynthesis.speaking) {
            this.stop()
        } else {
            const utterance = new SpeechSynthesisUtterance(paragraph.textContent)
            // utterance.voice = this.voice;
            // utterance.lang = this.voice.lang;
            window.speechSynthesis.speak(utterance)
            this.reading = true
        }
    }

    stop() {
        window.speechSynthesis.pause()
        window.speechSynthesis.cancel()
        this.reading = false
    }
}
