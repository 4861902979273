import {syllable} from 'syllable'

export function grade(text) {
    let easyWord = 0
    let difficultWord = 0
    let textList = split(text).slice(0, 100)

    for (let word of textList) {
        if (syllableCount(word) < 3) {
            easyWord += 1
        } else {
            difficultWord += 1
        }
    }
    text = textList.join(' ')
    let number = (easyWord + difficultWord * 3) / sentenceCount(text)
    let returnVal = number <= 20 ? (number - 2) / 2 : number / 2
    returnVal = Math.legacyRound(returnVal, 1)
    return !isNaN(returnVal) ? returnVal : 0.0
}
function sentenceCount(text) {
    let ignoreCount = 0
    let sentences = text.split(/ *[.?!]['")\]]*[ |\n](?=[A-Z])/g)
    for (let sentence of sentences) {
        if (lexiconCount(sentence) <= 2) ignoreCount += 1
    }
    const validSentences = sentences.length - ignoreCount
    return validSentences > 1 ? validSentences : 1
}
function syllableCount(text, lang = 'en-US') {
    text = text.toLocaleLowerCase(lang)
    text = removePunctuation(text)
    if (!text) return 0
    return syllable(text)
}
function removePunctuation(text) {
    text = text.replace(punctuationRE, '')
    return text
}
function split(text) {
    text = text.split(/,| |\n|\r/g)
    text = text.filter(n => n)
    return text
}
function lexiconCount(text, removeThePunctuation = true) {
    if (removeThePunctuation) text = removePunctuation(text)
    text = text.split(/,| |\n|\r/g)
    text = text.filter(n => n)
    return text.length
}
const punctuationRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g
Math.legacyRound = (number, points = 0) => {
    const p = 10 ** points
    return Math.floor((number * p) + Math.copySign(0.5, number)) / p
}
Math.copySign = (x, y) => {
    return x * (y / Math.abs(y))
}
