import {Controller} from "stimulus";
import random from 'lodash/random'

export default class extends Controller {
    connect() {
        this.element.addEventListener('crop', (e) => {
            const data = e.detail
            if(data && data.width) this.handleCrop(data)
        })
        this.modalId = `modal-${this.newCID}`

        const button = document.createElement('button')
        button.className = 'btn btn-primary'
        button.setAttribute('type', 'button')
        button.setAttribute('data-toggle', 'modal')
        button.setAttribute('data-target', `#${this.modalId}`)
        button.textContent = 'Crop'
        this.element.appendChild(button)


        const html = `
              <div class="modal modal-lg fade" id="${this.modalId}" tabindex="-1" role="dialog" style="max-width: 100%; width: 100%;">
                <div class="modal-dialog" role="document" style="max-width: 1050px">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Image Crop</h4>
                      <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                      <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                      <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </div>
                  <!-- /.modal-content-->
                </div>
                <!-- /.modal-dialog-->
              </div>
        `
        this.element.innerHTML += html

        $(this.element).find('.modal').on('shown.coreui.modal', () => {
            if (this.initialized) return
            const reactor = document.createElement('div')
            reactor.dataset.controller = "react"
            reactor.dataset.reactComponent = "image-crop"
            reactor.dataset.reactProps = this.element.dataset.props
            $(this.element).find('.modal-body').append(reactor)
            this.initialized = true
        })
    }

    disconnect() {
    }

    handleCrop(data) {
        const root = this.element
        root.querySelector('.width').setAttribute('value', data.width)
        root.querySelector('.height').setAttribute('value', data.height)
        root.querySelector('.x').setAttribute('value', data.x)
        root.querySelector('.y').setAttribute('value', data.y)
    }

    get newCID() {
        return random(100000, 999999).toString(36)
    }
}
