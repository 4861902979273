import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let statusClass, helpText
    const status = this.element.dataset.status

    switch (status) {
      case 'never':
        statusClass = 'secondary'
        helpText = 'This device is not yet in use.'
        break
      case 'connected':
        statusClass = 'success'
        helpText = 'This device is working normally.'
        break
      case 'disconnected':
        statusClass = 'info'
        helpText = 'This device is not connected'
        break
      case 'lost':
        statusClass = 'danger'
        helpText = 'This device cannot be reached.'
        break
      default:
        statusClass = 'secondary'
        helpText = null
    }

    this.element.classList.add(`badge-${statusClass}`)
    this.element.innerHTML = status.toUpperCase()
  }
}
