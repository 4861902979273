import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        document.getElementById('text-condensed').addEventListener('click', function () {
            document.getElementById('text-condensed').className = 'hidden'
            document.getElementById('text-expanded').className = 'visible'
            document.getElementById('linkages').className = 'visible'
        })
    }
}
