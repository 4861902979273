import {Controller} from "stimulus";
import {grade} from '../stuff/linsear-write-index'

export default class extends Controller {
    connect() {
        this.getInput(this.element)
        this.rateText()
        this.input.addEventListener('change', this.rateText.bind(this))
    }

    rateText() {
        const { value } = this.input
        if (value === '') {
            this.element.innerHTML = ''
            this.element.className = ''
        }

        const { min, max, zen } = this.element.dataset

        const level = grade(value)//.toFixed(0)
        let className = "text-"
        if (level < min || level > max) className += 'danger';
        else if (level > zen) className += 'warning'
        else className += 'default'

        this.element.innerHTML = `Grade ${level.toFixed(0)}`
        this.element.className = className
    }

    getInput(element) {
        this.input = element.previousSibling
        if (!this.input)
            return
        if (this.input.tagName === 'DIV')
            this.getInput(this.input)
    }
}
