import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        this.input = this.element.previousSibling
        this.countWords()
        this.input.addEventListener('change', this.countWords.bind(this))
    }

    countWords() {
        const { value } = this.input
        if (value === '') {
            this.element.innerHTML = '0 words'
            this.element.className = 'text-default'
        }

        const { min, max, zen } = this.element.dataset
        const numWords = value.replace(/\s+/g, ' ').split(' ').length
        let className = "text-"
        if (numWords < min || numWords > max) className += 'danger';
        else if (numWords > zen) className += 'warning'
        else className += 'default'

        this.element.innerHTML = numWords === 1 ? '1 word' : `${numWords} words`
        this.element.className = className
    }
}
