import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("play", () => this.start())
    this.element.addEventListener("ended", () => this.stop())
    this.element.addEventListener("pause", () => this.stop());
  }

  playing;

  disconnect() {
    this.stop();
  }

  start() {
    this.playing = true;
    window.dispatchEvent(new Event("audio-started"));
    document.getElementById('photo-panel').classList.add("playing");
  }

  stop() {
    this.playing = false;
    window.dispatchEvent(new Event("audio-stopped"));
    document.getElementById('photo-panel').classList.remove("playing");
  }
}
