import {Controller} from "stimulus";

export default class extends Controller {
    clicked = false
    connect() {
        const el = this.element
        this.element.querySelector('.form-check input[type=checkbox]').addEventListener('click', () => {
            this.clicked = !this.clicked
            if (this.clicked) {
                el.classList.add('removed')
            } else {
                el.classList.remove('removed')
            }
        })
    }

    disconnect() {
    }
}
