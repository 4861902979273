import consumer from "./consumer"

consumer.subscriptions.create('CmsChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected!")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.type === "CMS_DISPLAY_STATUS") {
      const id = data.payload.data.id;
      const powerLevel = data.payload.power_level;
      const status = data.payload.data.status;
      $(`#display-${id}`).replaceWith(`<span class="badge" id="display-${id}" data-controller="device-status" data-status="${status}"></span>`)
      if (el) {
        el.setAttribute("data-status", status);
      }
      $(`#display-${id}-battery`).html(`${powerLevel}%`);
    }
  }
});
