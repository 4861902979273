import {Controller} from "stimulus";
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.onEnd.bind(this),
            handle: '.handle'
        })
    }

    disconnect() {
        if (this.sortable)
            this.sortable.destroy()
    }

    onEnd() {
        const selector = this.element.dataset.selector || '.list-group-item'
        this.element.querySelectorAll(selector).forEach((elem, index) => {
            const input = elem.querySelector('.position')
            if (input)
                input.setAttribute('value', index + 1)
        })
    }
}
