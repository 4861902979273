import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.add("visible");
    this.startTime = this.element.dataset.start;
    window.addEventListener("audio-started", () => this.start());
    window.addEventListener("audio-stopped", () =>  this.stop());
  }

  disconnect() {
    this.stop();
  }

  timeout;
  startTime;

  start() {
    this.element.classList.remove("visible");
    this.timeout = window.setTimeout(() => {
      this.element.classList.add("visible");
    }, this.startTime)
  }

  stop() {
    window.clearTimeout(this.timeout);
    this.element.classList.add("visible");
  }
}
