// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
import "chartkick/chart.js"
import "channels"
import "components"
import "controllers"
import "../stuff/chosen.jquery"

import "../../../node_modules/@coreui/coreui/scss/coreui"
import "../stuff/cms.scss"

$(document).ready(function () {
    // $('.c-sidebar').sidebar();
    $('select').initChosen()
    $('[rel=tooltip]').tooltip({ container: 'body', trigger: 'hover' })
})

$(document).on('click', 'button[data-type="add-other-array"]', function (e) {
    e.preventDefault()
    e.stopPropagation()
    const list = $(this).prev('div.list-group')
    const row = list.find('.list-group-item:first').clone()
    row.find('input').val(null)
    list.append(row)
})

$(document).on('click', 'button.array-remove', function (e) {
    e.preventDefault()
    e.stopPropagation()
    $(this).closest('.row').remove()
})

$.fn.extend({
    initChosen: function () {
        return this.each(function () {
            const addable = $(this).attr('data-addable')
            const options = {
                search_contains: true,
                single_backstroke_delete: false,
                allow_single_deselect: true,
                placeholder_text_single: 'Choose',
                placeholder_text_multiple: 'Choose',
            }
            if (addable) {
                options.no_results_text = "No result found. Press enter to add new."
                options.addable = true;
            } else {
                options.disable_search_threshold = 8;
            }
            $(this).chosen(options)
        })
    }
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
